/* global jsVars */
import Swiper, { Autoplay, Pagination, EffectFade } from 'swiper';
Swiper.use([Autoplay, Pagination, EffectFade]);

const bannerVideo = document.getElementById('banner-video');

document.addEventListener('DOMContentLoaded', () => {
	if (document.body.contains(bannerVideo)) {
		const fullScreen = document.getElementById('full-screen-media');
		const mediaSlider = document.getElementById('media-slider');
		const imageFullScreen = fullScreen.querySelector('img');
		const media = document.querySelector('.tlw__banner__wrapper .video');
		const title = document.querySelector('.tlw__banner__wrapper .title');
		const containerPagination = document.querySelector('.pagination-text');
		const pagination = document.querySelectorAll('.pagination-text__button');
		const containerCarrousel = document.querySelector('.carrousel__banner');
		const initSlider = document.body.contains(containerCarrousel)
			? containerCarrousel.getAttribute('data-init-slider')
			: '';
		const timeSlider = document.body.contains(containerCarrousel)
			? containerCarrousel.getAttribute('data-time-slider')
			: '';
		const swiperPagination = document.querySelector('.tlw__banner__wrapper .swiper-pagination');
		const mediaSliderElements = mediaSlider.querySelectorAll('.media-player');
		const root = document.querySelector(':root');
		const textBanner = [];
		let bannerCarrousel;
		let isClass = true;
		const newTimeAnimation = timeSlider / 1000;

		root.style.setProperty('--timeSliderPrincipal', newTimeAnimation + 's');
		if (media.classList.contains('active')) {
			isClass = false;
		}
		fullScreen.addEventListener('click', () => {
			isClass = !isClass;
			media.classList.toggle('active');
			title.classList.toggle('active');
			if (isClass) {
				imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen.svg';
				title.classList.remove('active');
				if (
					document.body.contains(containerCarrousel) &&
					containerCarrousel.classList.contains('swiper-initialized')
				) {
					containerCarrousel.classList.remove('active');
					swiperPagination.classList.add('hide');
					containerPagination.classList.remove('hide');
					setTimeout(function () {
						bannerCarrousel.destroy(true, true);
					}, 500);
					mediaSliderElements.forEach((elm) => {
						elm.classList.remove('active');
					});
				}
			} else {
				imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen-2.svg';
			}
		});
		for (let i = 0; i < pagination.length; ++i) {
			const text = pagination[i].textContent;

			textBanner.push(text);
		}
		// eslint-disable-next-line no-inner-declarations
		function initSwpier(indexSlide) {
			bannerCarrousel = new Swiper('.carrousel__banner', {
				slidesPerView: 'auto',
				spaceBetween: 0,
				initialSlide: indexSlide,
				watchOverflow: true,
				effect: 'fade',
				fadeEffect: {
					crossFade: true,
				},
				autoplay: {
					delay: timeSlider,
					disableOnInteraction: false,
				},
				pagination: {
					el: swiperPagination,
					clickable: true,
					renderBullet: function (index, className) {
						return (
							'<span class="' +
							className +
							'">' +
							'<span class="progressive-text">' +
							textBanner[index] +
							'</span>' +
							'<span class="progressive-line">' +
							'<i></i>' +
							'<b></b>' +
							'</span>' +
							'</span>'
						);
					},
				},
			});
			bannerCarrousel.on('slideChangeTransitionStart', function (swiper) {
				mediaSliderElements[swiper.previousIndex].classList.remove('active');
				mediaSliderElements[swiper.activeIndex].classList.add('active');
			});
		}
		for (let i = 0; i < pagination.length; i++) {
			pagination[i].addEventListener('click', function (e) {
				e.preventDefault();
				isClass = false;
				containerCarrousel.classList.add('active');
				media.classList.add('active');
				initSwpier(i);
				title.classList.remove('active');
				if (!isClass) {
					imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen-2.svg';
					if (containerCarrousel.classList.contains('swiper-initialized')) {
						containerPagination.classList.add('hide');
						swiperPagination.classList.remove('hide');
					}
				} else {
					imageFullScreen.src = jsVars.theme_root + '/assets/img/icon-full-screen.svg';
				}
				mediaSliderElements[i].classList.add('active');
			});
		}
		if (initSlider === '1') {
			mediaSliderElements[0].classList.add('active');
			containerCarrousel.classList.add('active');
			initSwpier(0);
		}
	}
});
